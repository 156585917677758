(function () {
	angular.module('unreal.ui')
		.config(['$locationProvider', '$routeProvider', function ($locationProvider) {
			$locationProvider.html5Mode({
				enabled: false,
				requireBase: false
			}).hashPrefix('');
		}]);

	angular.module('unreal.ui')
		.run(function(editableOptions, editableThemes) {
			editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
			editableThemes.bs3.inputClass = 'input-sm';
			editableThemes.bs3.buttonsClass = 'btn-sm';
		});
})();
