console.log("Service Worker Loaded...");

self.addEventListener("push", e => {
  const data = e.data.json();

  function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    }

    var convertedDate = convertUTCDateToLocalDate(new Date(data.date));

    self.registration.showNotification(data.title, {
          body: data.body + ' ' + '\nSent on: ' + convertedDate,
          icon: data.icon,
          requireInteraction: true,
    });
});
