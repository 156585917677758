const LOGIN_URL = 'https://api.un-real.com/oauth/unreal';

function checkTokenExists() {
    console.log("############  Get token first ###########");
    // optimum
    //var url = new URL(window.location);
    //var token = url.searchParams.get("token");
    if (window.location.hash) {
        var token = window.location.hash.substr(3).split("=")[1];
        if (!token) {
            return window.location.replace(LOGIN_URL);
        }
        if (token && token.length === 0) {
            console.log('Unathorized! Token not found!');
            return window.location.replace(LOGIN_URL);
        }

    } else {
        return window.location.replace(LOGIN_URL);
    }
}

checkTokenExists();