(function() {
    'use strict';

    angular.module('unreal.ui', [
        'ngRoute',
        'ui.calendar',
        'ui.bootstrap',
        'ui.select',
        'ds.clock',
        'angularFileUpload',
        'ngjsColorPicker',
        '720kb.datepicker',
        'ui.bootstrap.datetimepicker',
        'gridster',
        'ui.checkbox',
        'ui.sortable',
        'xeditable',
		'angularjs-dropdown-multiselect',
        'unreal.api',
        'unreal.ui.core',
        'unreal.ui.utils',
        'unreal.ui.directives',
        'unreal.ui.shell',
		'unreal.ui.forms',
        'unreal.ui.messages',
        'unreal.ui.dashboard',
        'unreal.ui.calendar',
        'unreal.ui.projects',
        'unreal.ui.project',
        'unreal.ui.transcreation',
		'unreal.ui.reviewApprove',
        'unreal.ui.documentManager',
        'unreal.ui.assets',
        'unreal.ui.workflow',
        'unreal.ui.teamBuilder',
		'unreal.ui.bookings',
		'unreal.ui.audio',
		'unreal.ui.wekan',
		'unreal.ui.gantt',
        'unreal.ui.todo',
	    'unreal.ui.voice',
        'unreal.ui.search',
        'unreal.ui.talk',
		'unreal.ui.compliance',
		'unreal.ui.captionandsubtitles',
		'unreal.ui.wordplay',
        'unreal.ui.meetingRoom',
	 'unreal.ui.notification',
	'unreal.ui.users',
    ]);
})();


function checkTokenExists() {
    if(window.parent.angular.token != null) {
        return window.parent.angular.token;
    } else {
        console.log('Unauthorized! Token not found!')
    }
}
angular.element(document).ready(function(){
    var Token = window.parent.angular.token;
    $('.token-id').html(Token);
});

